@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --primary-color: #1BA397;
  --secondary-color: #abe8e3;
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab {
  color: white;
}

.left-shadow {
  box-shadow: -10px 0 15px -4px rgba(16, 15, 15, 0.1), -10px 0 6px -2px rgba(0, 0, 0, 0.05);
}

.ant-picker-now-btn {
  display: none;
}


/* App.css */
input[type="radio"].custom-radio:checked {
  border: 0.15em solid var(--primary-color);
  /* background-color: var(--primary-color); */
}
input[type="radio"].custom-radio {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: var(--primary-color);
  width: 1.5em;
  height: 1.5em;
  border: 0.15em solid var(--primary-color);
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="radio"].custom-radio::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  border-color: var(--primary-color);
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--primary-color);
  /* Adjust the color here */
}
input[type="radio"].custom-radio:checked::before {
  transform: scale(1);
}


input[type="radio"].custom-radio1:checked {
  border: 0.15em solid var(--primary-color);
  /* background-color: var(--primary-color); */
}
input[type="radio"].custom-radio1 {
  appearance: none;
  background-color: #fff;
  margin: 0;
  /* font: inherit; */
  color:gray;
  width: 1em;
  height: 1em;
  border: 0.15em solid  gray;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="radio"].custom-radio1::before {
  content: "";
  width: 0.35em;
  height: 0.35em;
  border-radius: 50%;
  border-color: var(--primary-color);
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--primary-color);
  /* Adjust the color here */
}
input[type="radio"].custom-radio1:checked::before {
  transform: scale(1);
}


/* CheckBox Styling */

/* Checkbox Style 1 */
input[type="checkbox"].custom-checkbox:checked {
  border: 0.15em solid var(--primary-color);
}
input[type="checkbox"].custom-checkbox {
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: var(--primary-color);
  width: 1.3em;
  height: 1.3em;
  border: 0.15em solid var(--primary-color);
  border-radius: 0.28em; /* square checkboxes with rounded edges */
  display: grid;
  place-content: center;
  transition: background-color 120ms ease-in-out;
}
input[type="checkbox"].custom-checkbox::before {
  content: "";
  width: 0.8em;
  height: 0.8em;
  transform: scale(0);
  transition: transform 120ms ease-in-out;
  background-color: var(--primary-color); /* inner check color */
  border-radius: 0.15em;
}
input[type="checkbox"].custom-checkbox:checked::before {
  transform: scale(1);
}

/* Checkbox Style 2 */
input[type="checkbox"].custom-checkbox1:checked {
  border: 0.15em solid gray;
}
input[type="checkbox"].custom-checkbox1 {
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: gray;
  width: 1.2em;
  height: 1.2em;
  border: 0.15em solid gray;
  border-radius: 0.25em; /* square checkboxes with rounded edges */
  display: grid;
  place-content: center;
  transition: background-color 120ms ease-in-out;
}
input[type="checkbox"].custom-checkbox1::before {
  content: "";
  width: 0.6em;
  height: 0.6em;
  transform: scale(0);
  transition: transform 120ms ease-in-out;
  background-color: gray; /* inner check color */
  border-radius: 0.15em;
}
input[type="checkbox"].custom-checkbox1:checked::before {
  transform: scale(1);
}


.my-class {
  border-color:#dc2626 !important;
  border: 1px solid #dc2626 !important;
  border-radius: 3rem !important;
  padding: 0.8rem 1rem !important;
  background-color: #dc2626 !important;
  font-size: large !important;
  font-weight: 800 !important;
  color: white !important;
}
.my-caret-class {
  background:whitesmoke !important;
  color: #dc2626 !important;
  border: 1px solid #dc2626 !important;
  border-radius: 50% !important;
  width: 18rem !important;
  height: 18rem !important;
}
.my-overlay-class {
  background:#dc2626 !important;
}



.custom-shadow {
  box-shadow: 0px -12px 12px 3px rgba(27, 163, 151, 0.2);
}
.custom-shadows {
  box-shadow: 0px -8px 8px 2px rgba(27, 163, 151, 0.2);
}
.custom-shadow:hover {
  box-shadow: 0px -12px 12px 3px rgba(27, 163, 151, 0.2);
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab-active {
  color: #1BA397;
}

.custom-tabs .ant-tabs-ink-bar {
  background-color: #1BA397;
}
.text-primary {
  color: var(--primary-color);
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}

/* .bg-primary:hover {
  background-color: var(--secondary-color);
} */

.border-primary {
  border-color: var(--primary-color);
}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input::placeholder {
  font-size: 0.88rem;
}
textarea::placeholder {
  font-size: 0.88rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
body {
  font-family: "Poppins", sans-serif;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/*input[type="text"] {
  outline: none;
}*/
/*input[type="text"]:focus {
  border: 3px solid #555;
}*/
/*select.some-class {
  border-right: 8px solid transparent;
}*/
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #18b871;
  border: #18b871;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #18b871;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background: #18b871;
  border-color: #18b871;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-class1 .anticon svg {
  color: white;
}
.some.anticon svg {
  color: black;
}

.some1.anticon svg {
  color: black;
  margin-bottom: 4px;
}

.slick-arrow {
  background-color: var(--primary-color) !important; /* Change the background color */
  color: #fff !important; /* Change the text color */
 /* Change the font size */
 border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


.wow.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: white;
  border-width: 1px;
  /* border: solid; */
  border-color: #c6cfe5;
  border-radius: 0.3rem;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  color: black;
  width: 100%;
}

.disabled-shadow {
 
  
  pointer-events: none; /* Disable interaction */
  opacity: 0.6; /* Reduce opacity */
}



.woww.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: white;
  padding: 0.5rem;
  height: 2.8rem;
  /* border-width: 1px; */
  /* border: solid; */
  /* border-color: #c6cfe5; */
  border-radius: 0.3rem;
  font-size: 1.1rem;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* padding-top: 1.3rem; */
  /* padding-bottom: 1.3rem; */
  /* color: black; */
  /* width: 100%; */
}


.wow1.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f2f4f8;
  border: none;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  width: 100%;
  padding: 0.5rem;
}

.wow10.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #F6F4F4;
  border: none;
  font-size: 0.85rem;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  width: 100%;
  height: 2.5rem;
}
.wow9.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: rgb(235, 235, 235);
  border-width: 1px;
  /* border: solid; */
  border-color: #c6cfe5;
  border-radius: 0.3rem;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  color: black;
  width: 100%;
}
.ant-select-single {
  width: 100%;
}

.ant-select .ant-select-arrow .anticon > svg {
  color: #a2a7a4;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #a2a7a4;
}

.ant-picker {
  width: 100%;
  background: white;
  border-width: 1px;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border-color: #c6cfe5;
  border-radius: 0.3rem;
}

.date-class .ant-picker-input > input:placeholder-shown {
  font-size: 1.1rem;
  font-weight: 600;
  color: #a2a7a4;
}

.date-class .anticon svg {
  color: #a2a7a4;
  font-size: 1.1rem;
}
.date-class1 .ant-picker-input > input:placeholder-shown {
  font-size: 1.1rem;
  font-weight: 600;
  color: #a2a7a4;
}

.date-class1 .anticon svg {
  color: #a2a7a4;
  font-size: 1.1rem;
}

.mobile-screen {
  display: block; /* Display this UI block by default */
}
.larger-screen {
  display: none; /* Hide this UI block by default */
}

/* Define media query for screens larger than 768px */
@media (min-width: 768px) {
  .mobile-screen {
    display: none; /* Hide this UI block for larger screens */
  }
  .larger-screen {
    display: block; /* Display this UI block for larger screens */
  }
}

.mobile-screen {
  display: none; /* Hide this UI block by default */
}
.larger-screen {
  display: block; /* Display this UI block by default */
}

/* Define media query for screens smaller than or equal to 768px */
@media (max-width: 768px) {
  .mobile-screen {
    display: block; /* Display this UI block for smaller screens */
  }
  .larger-screen {
    display: none; /* Hide this UI block for smaller screens */
  }
}





.date-classMerchant .ant-picker-input > input:placeholder-shown {
  font-size: 1.3rem;
  font-weight: 600;
}

.date-classMerchant .anticon svg {
  color: #a2a7a4;
  font-size: 1.1rem;
}

.ant-picker .ant-picker-input > input {
  font-size: 1.1rem;
}
.ant-space {
  background: #cbd5e1;
  padding: 1rem;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 0.3rem;
  width: 12rem;
  display: flex;
  justify-content: space-between;
}

.checkamount .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.checkk .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.checkk3 .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.checkk4 .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}



.checkk1 .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}


.checkk2 .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}


.checkk5 .ant-checkbox-checked .ant-checkbox-inner {
  background-color: white; /* Background color of the checkbox when checked */
  border-color: white; /* Border color of the checkbox when checked */
}

.checkk5 .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color:  var(--primary-color); /* Color of the tick (checkmark) */
  border-width: 2.4px;
}


.checkk6 .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color); /* Background color of the checkbox when checked */
  border-color: var(--primary-color); /* Border color of the checkbox when checked */
}

.checkk6 .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color:  white; /* Color of the tick (checkmark) */
  border-width: 2.4px;
}

 .rmdp-shadow {
  box-shadow: 0px 0px 3px rgba(20, 11, 11, 0.1) !important; /* Adding !important to ensure it takes precedence */
}

.custom-calendar .rmdp-week-day{
  color: var(--primary-color);
}

.custom-calendar .rmdp-day.rmdp-today span{
  background-color: rgba(245, 245, 245, 0.986);
  color: rgb(23, 21, 21);
  border-radius: 50%;
  width: 100%;
}

.custom-calendar .rmdp-day.rmdp-today span:hover {
  background-color: #43c4b9;
  color: white;
  border-radius: 50%;
}


/* .rmdp-calendar {

  border-radius: 0.5rem;
  width: 20rem;
} */

/* .rmdp-day-picker{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5.5rem;
} */



.custom-calendar .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--primary-color);
  color: white;
  border-radius: 50%;
}


.checkk {
  margin-bottom: 0.8rem;
  
}

.checkamount .ant-checkbox + span {
  font-size: 0.95rem;
  font-weight: 400;
}

.checkamount .ant-checkbox .ant-checkbox-inner {
  border-radius: 0.4rem;
  padding: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkk .ant-checkbox + span {
  font-size: 1.1rem;
  margin-left: 1rem;
  font-weight: 500;
}
.checkk .ant-checkbox .ant-checkbox-inner {
  border-radius: 0.4rem;
  padding: 0.75rem;
}
.checkk .ant-checkbox-checked .ant-checkbox-inner:after {
  width: 0.45rem;
  height: 0.85rem;
}

.checkk1 {
  margin-bottom: 0.8rem;
}
.checkk1 .ant-checkbox + span {
  font-size: 1.2rem;
  margin-left: 0.8rem;
  font-weight: 500;
}
.checkk1 .ant-checkbox .ant-checkbox-inner {
  border-radius: 0.3rem;
  padding: 0.7rem;
}
.checkk1 .ant-checkbox-checked .ant-checkbox-inner:after {
  width: 0.4rem;
  height: 0.9rem;
}


.checkk5 {
  margin-bottom: 0.8rem;
}
.checkk5 .ant-checkbox + span {
  font-size: 1.2rem;
  margin-left: 0.8rem;
  font-weight: 500;
}
.checkk5 .ant-checkbox .ant-checkbox-inner {
  border-radius: 0.3rem;
  padding: 0.7rem;
}
.checkk5 .ant-checkbox-checked .ant-checkbox-inner:after {
  width: 0.4rem;
  height: 0.9rem;
}

.checkk6 {
  margin-bottom: 0.8rem;
}
.checkk6 .ant-checkbox + span {
  font-size: 1.2rem;
  margin-left: 0.8rem;
  font-weight: 500;
}
.checkk6 .ant-checkbox .ant-checkbox-inner {
  border-radius: 0.3rem;
  padding: 0.7rem;
}
.checkk6 .ant-checkbox-checked .ant-checkbox-inner:after {
  width: 0.4rem;
  height: 0.9rem;
}

.checkk2 {
  margin-bottom: 0.8rem;
}
.checkk2 .ant-checkbox + span {
  font-size: 0.9rem;
  margin-left: 0.4rem;
  font-weight: 500;
}
.checkk2 .ant-checkbox .ant-checkbox-inner {
  border-radius: 0.4rem;
  padding: 0.6rem;
}
.checkk2 .ant-checkbox-checked .ant-checkbox-inner:after {
  width: 0.45rem;
  height: 0.85rem;
}

.checkk3 {
  margin-bottom: 0.8rem;
}
.checkk3 .ant-checkbox + span {
  font-size: 1rem;
  margin-left: 0.3rem;
  font-weight: 500;
}
.checkk3 .ant-checkbox .ant-checkbox-inner {
  border-radius: 0.4rem;
  padding: 0.75rem;
}
.checkk3 .ant-checkbox-checked .ant-checkbox-inner:after {
  width: 0.45rem;
  height: 0.85rem;
}


.checkk4 .ant-checkbox + span {
  font-size: 0.95rem;
  margin-left: 0.2rem;
  font-weight: 400;
}
.checkk4 .ant-checkbox .ant-checkbox-inner {
  border-radius: 0.4rem;
  padding: 0.6rem;
}
.checkk4 .ant-checkbox-checked .ant-checkbox-inner:after {
  width: 0.45rem;
  height: 0.85rem;
}
.show-action {
  display: flex;
  flex-direction: column;
}

.hide-action {
  display: none;
}

.ant-input-affix-wrapper-lg {
  padding: 5px 11px;
}

.Search .anticon svg {
  margin-right: 0.5rem;
}

.custom-phone-input .PhoneInputInput {
  border: none !important;
  outline: none;
}

.custom-phone-input .PhoneInputCountrySelect {
  border: none !important;
  outline: none;
}

.custom-phone-input .PhoneInput {
  border: none !important;
  outline: none;
}


.PhoneInputInput {
  padding: 0.5rem;
  background: white;
  border-width: 1px;
  border-color: #c6cfe5;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 400;
  margin-left: 1rem;
  width: 84%;
}

.PhoneInputCountryIcon img {
  border-radius: 0.2rem;
  width: 3rem;
}

.PhoneInputCountrySelectArrow{
  display: none;

}

.PhoneInputCountrySelect {
  width: 1rem;
  margin-left: 1rem;
  padding: 10px;
  top: auto !important;
  bottom: 0 !important;
}
.PhoneInputCountry {
  display: flex;
  /* flex-direction: row-reverse; */
  align-items: center;
}
.PhoneInput {
  display: flex;
  height: 4rem;
  align-items: center;
}
.ant-picker-footer .ant-btn-primary {
  background-color: #1BA397;
  border-color: #1BA397;
  color: #fff; 
}

.ant-picker-footer .ant-btn-primary:hover {
  background-color: #1c7971;
  border-color: #1c7971;
}

* {
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  --ReactInputVerificationCode-itemHeight: 2.5rem;
}

.ant-pagination .ant-pagination-item {
  border: 1px solid var(--primary-color);
  border-radius: 0.3rem;
}

.ant-pagination .ant-pagination-item-active a {
  background: var(--primary-color);
  color: white;
}

.ant-dropdown .ant-dropdown-menu {
  /* width: 12.5rem; */
  font-size: medium;
  background-color: #f2f8f5;
  font-size: large;
  padding: 10px;
  border-bottom: 1px solid var(--primary-color);
}


.dropdown1 .ant-dropdown .ant-dropdown-menu  {
  width: 1rem;
  background-color: #1d4330;
  font-size: large;
  padding: 10px;
  border-bottom: 1px solid var(--primary-color);
}

/* Style specific to the custom dropdown */
.custom-dropdown .ant-dropdown-menu {
  background-color: #ad2c2c; /* Example: Change background color */
}

.custom-dropdown .ant-dropdown-menu-item {
  font-size: 1.2rem; /* Example: Change font size */
  color: #333;
}

.custom-dropdown .ant-dropdown-menu-item:hover {
  background-color: #f35929; /* Example: Change hover background */
  color: #fff;
}


.triangle-bl {
  width: 0;
  height: 0;
  border-bottom: 60px solid #0ca6fb;
  border-right: 200px solid transparent;
}
.triangle-br {
  width: 0;
  height: 0;
  border-bottom: 100px solid #07588f;
  border-left: 300px solid transparent;
}

.diamond {
  width: 40px;
  height: 40px;
  background: #07588f;
  margin: 3px 0 0 30px;
  /* Rotate */
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  /* Rotate Origin */
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.input-container {
  position: relative;
  background: white;
  border-width: 1px;
  border-color: #c6cfe5;
  padding: 0.1rem;
  border-radius: 0.5rem;
}

.input-container img {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input-container input {
  padding: 0.5rem; /* Adjust the value to match the width of the icon */
  background: white;
  font-size: 1.1rem;
  border-radius: 0.5rem;
  width: 100%;
}
.input-container input:focus {
  outline: none;
}
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}

.dancing {
  font-family: "Dancing Script", cursive;
}
.dancing1 {
  font-family: "Parisienne", cursive;
}

.div {
  display: block;
  position: relative;
  top: -2px;
  height: 200px;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 1333 200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="rgba(47, 73, 94, 1)" d="M 0 35 C 338.5 35 338.5 110 677 110 L 677 110 L 677 0 L 0 0 Z" stroke-width="0"></path> <path fill="rgba(47, 73, 94, 1)" d="M 676 110 C 1012.5 110 1012.5 22 1349 22 L 1349 22 L 1349 0 L 676 0 Z" stroke-width="0"></path> </svg>');
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1332px) {
  .div {
    background-size: contain;
  }
}

.sett {
  background-image: url("./imgs/back.png");
  background-position: center;
  object-fit: cover;
  background-blend-mode: lighten;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;

  border-top: 15px solid var(--primary-color);
}
.arrow-down1 {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 20px solid #ededed;
}

/* 
input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid black;
}

input[type='radio']:checked:after {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #57D38C;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid 57D38C;

} */
.embeded-container {
  height: 500px;
  width: 1100px;
}
/* .msgstore::-webkit-scrollbar {
  display: none;
} */
.iconeatach
{
  color: gray;
  font-size: 22px;
}
.login_btn:hover
{
  /* color:white; */
  background-color: #c1d4df14;
}
.button-containerrr {
  position: relative;
  border-radius:100px;
  color:black;
  border:1px solid gray; 
  width:40px;
  height:40px; 
  cursor:pointer; 
  background-color:white;
  margin-left:3px;
  display:flex;
  justify-content:center;
  align-items:center;
}

.popup-containerrr {
  position: absolute;
  top: 325px;
  right:13px;
  z-index: 1000;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.btnmedia
{
  border-radius:100px;
  margin-bottom:3px; 
  border-radius:100px;
  color:black;
  border:1px solid gray; 
  width:35px;
  height:35px; 
  cursor:pointer; 
  background-color:white;
  display:flex;
  justify-content:center;
  align-items:center;
}
.myCall {
  border: 1px solid rgb(173, 170, 170);
  border-radius: 80%;
  background-color: var(--primary-color);
  font-size: 20px;
  margin: 5px;
  padding: 3px;
    cursor: pointer;
  color: white;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.bg-custom-color {
  background-color: #F2F4F8; /* Replace with your desired color */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.chats-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

  .chats-scrollbar::-webkit-scrollbar-track {
      /* background-color: #662323; */
  }
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #bcc2bc;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #b8c4c3 #FFFFFF;
}

.custom-carousel .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 30px;
  height: 30px;
  background-color: transparent !important;
  line-height: 1;
}

.custom-carousel .slick-prev {
  left: 10px;
}

.custom-carousel .slick-next {
  right: 10px;
}

.custom-carousel:hover .slick-arrow {
  display: block; /* Show arrows on hover */
}
.react-time-picker__wrapper {
  border: 1px solid #d1d5db; /* Lighter border color */
  border-radius: 0.375rem; /* md: 6px rounded */
  padding: 0.25rem; /* Optional: Add some padding */
}

.react-time-picker__inputGroup__input {
  border: none; /* Remove the internal border */
  outline: none; /* Remove the outline on focus */
  padding: 0.5rem; /* Optional: Adjust input padding */
  border-radius: 0.375rem; /* md: 6px rounded */
}

.react-time-picker__inputGroup__divider {
  margin: 0 0.25rem; /* Optional: Adjust divider margin */
}